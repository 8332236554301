import { __decorate } from "tslib";
// Watch
import { Component, Vue, Watch } from 'vue-property-decorator';
import { cityStore, mainStore, parceriaCupomStore, prospexStore, userActionStore, userToolStore, parceriaCupomHistoricoStore, companyConfiguracaoCursoDescontoStore, } from '@/store';
import SearchCityComponent from '@/components/SearchCityComponent.vue';
import SearchEntidadeComponent from '@/components/SearchEntidadeComponent.vue';
import SearchEntidadeListComponent from '@/components/SearchEntidadeListComponent.vue';
import OcorrenciaComponent from '@/components/OcorrenciaComponent.vue';
import SaveButton from '@/components/SaveButton.vue';
import LogShowComponent from '@/components/LogShowComponent.vue';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
import PerdaComercialComponent from '@/components/PerdaComercialComponent.vue';
import { regexValidacaoEmail, regexValidacaoCelular } from '@/utils';
import _ from 'lodash';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
import { newProspex } from '@/interfaces/prospex';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value,
});
Validator.extend('numeric', {
    getMessage: (field) => `${field} deve ter apenas números!`,
    validate: async (value) => /^([0-9]+)$/.test(value),
});
Validator.extend('email', {
    getMessage: (field) => `${field} deve ser um email correto!`,
    validate: async (email) => {
        const re = regexValidacaoEmail();
        return !!re.test(email);
    },
});
Validator.extend('celular', {
    getMessage: (field) => `O número de ${field} deve estar no formato (XX) XXXXX-XXXX.`,
    validate: async (celular) => {
        const re = regexValidacaoCelular();
        return !!re.test(celular);
    },
});
let Create = class Create extends Vue {
    constructor() {
        super(...arguments);
        this.created_at = null;
        this.created_hour = null;
        this.entidadeSelected = null;
        this.entidadeAgendamentoSelected = null;
        this.needVerify = false;
        this.prospexExistente = null;
        this.dialogProspexExistente = false;
        this.gerouAgendamento = false;
        this.dataAgendamento = null;
        this.scheduleTime = null;
        this.loading = false;
        this.tab = 'tab_dados_cadastrais';
        this.id = null;
        this.entityObject = null;
        this.search = '';
        this.selectedItem = {};
        this.atendenteSelected = null;
        this.alunoIndicouSelected = null;
        this.selectedCupom = null;
        this.prospexSemCupom = false;
        this.prospexSemCupomErrors = [];
        this.curso_interesse_errors = [];
        this.dias_preferencia_errors = [];
        this.periodo_preferencia_errors = [];
        this.rodouSubmit = false;
        this.loadingEdit = false;
        this.isValidCursou = true;
        this.cidadeSelected = { codigo_municipio_completo: null };
    }
    get allowView() {
        if (userToolStore.userToolsIndexed.Prospex) {
            return userToolStore.userToolsIndexed.Prospex[0].allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed.Prospex) {
            return userToolStore.userToolsIndexed.Prospex[0].allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed.Prospex) {
            return userToolStore.userToolsIndexed.Prospex[0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed.Prospex) {
            return userToolStore.userToolsIndexed.Prospex[0].allow_delete;
        }
    }
    get companyConfiguracaoCursoDesconto() {
        return companyConfiguracaoCursoDescontoStore.companyConfiguracaoCursoDescontos;
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get duracaoTempoCursouIdioma() {
        return [
            'Menos de 6 meses',
            'Entre 6 meses e 1 ano',
            'Entre 1 e 2 anos',
            'Entre 2 e 4 anos',
            '5 anos ou mais'
        ];
    }
    get redeSocialOptions() {
        return [
            'Whatsapp',
            'Instagram',
            'Facebook',
            'Tiktok',
            'Telegram',
            'X (Twitter)',
            'Pinterest',
            'Linkedin',
            'Discord',
            'Youtube',
            'Snapchat',
            'Kwai',
        ];
    }
    onEntidadeSelected(param) {
        this.entidadeSelected = param;
    }
    onEntidadeAgendamentoSelected(param) {
        this.entidadeAgendamentoSelected = param;
    }
    async watchIdade(val, oldVal) {
        if (oldVal !== undefined) {
            this.needVerify = true;
        }
        await this.verifyPerson();
    }
    async watchName(val, oldVal) {
        if (oldVal !== undefined) {
            this.needVerify = true;
        }
    }
    async onChangeProspexSemCupom(val) {
        if (val && !this.loadingEdit) {
            this.entityObject.como_conheceu_knn_id = 1; // Visita Espontanea
        }
    }
    async onChangeCupom(val) {
        if (val) {
            if (val.id) {
                this.entityObject.como_conheceu_knn_id = 4; // 4 - Oriundo de parceria
                const cupom = await parceriaCupomStore.getParceriaCupomByParceriaId(val.id);
                this.entityObject.parceria_cupom_id = val.id;
                if (!this.entityObject.idade) {
                    this.entityObject.idade = cupom.idade;
                }
                if (!this.entityObject.nome) {
                    this.entityObject.nome = cupom.nome;
                }
                if (!this.entityObject.celular) {
                    if (cupom.telefone.length === 11 && cupom.telefone.charAt(2) === '9') {
                        this.entityObject.celular = cupom.telefone;
                    }
                    else {
                        this.entityObject.fone_fixo = cupom.telefone;
                    }
                }
                if (!this.entityObject.email) {
                    this.entityObject.email = cupom.email;
                }
            }
        }
    }
    async visualizarProspexExistente() {
        const id = this.prospexExistente[0].id;
        await this.$router.push({ name: 'main-prospexs-edit', params: { id } });
        this.$router.go(0);
        this.dialogProspexExistente = false;
    }
    async verifyPerson() {
        if (this.needVerify) {
            const idade = this.entityObject.idade;
            const name = this.entityObject.nome;
            if (name.length !== 0 && idade !== null) {
                this.prospexExistente = await prospexStore.getExistingProspex({
                    name,
                    idade,
                });
                if (this.prospexExistente && this.prospexExistente[0]) {
                    if (this.prospexExistente[0].id == this.id) {
                        return;
                    }
                    if (!this.prospexExistente) {
                        this.dialogProspexExistente = false;
                    }
                    else if (this.prospexExistente.length > 0) {
                        this.dialogProspexExistente = true;
                    }
                    else {
                        this.dialogProspexExistente = false;
                    }
                }
            }
        }
        this.needVerify = false;
    }
    get prospexs() {
        return prospexStore.prospexs;
    }
    showLog(ref) {
        const form = ref;
        form.show();
    }
    async verificaCursou() {
        let itensVerificar = [
            { value: this.entityObject.cursou_ingles, message: '"Já fez inglês?" deve ser informado!' },
            { value: this.entityObject.cursou_alemao, message: '"Já fez alemão?" deve ser informado!' },
            { value: this.entityObject.cursou_espanhol, message: '"Já fez espanhol?" deve ser informado!' },
            { value: this.entityObject.cursou_frances, message: '"Já fez francês?" deve ser informado!' },
            { value: this.entityObject.cursou_italiano, message: '"Já fez italiano?" deve ser informado!' },
            { value: this.entityObject.estuda, message: '"Estuda?" deve ser informado!' },
            { value: this.entityObject.trabalha, message: '"Trabalha?" deve ser informado!' }
        ];
        if (this.userProfile.company.escola_id !== 1) {
            itensVerificar = [
                { value: this.entityObject.cursou_ingles, message: '"Já fez inglês?" deve ser informado!' },
                { value: this.entityObject.cursou_espanhol, message: '"Já fez espanhol?" deve ser informado!' },
                { value: this.entityObject.cursou_frances, message: '"Já fez francês?" deve ser informado!' },
                { value: this.entityObject.estuda, message: '"Estuda?" deve ser informado!' },
                { value: this.entityObject.trabalha, message: '"Trabalha?" deve ser informado!' }
            ];
        }
        let valid = true;
        _.forEach(itensVerificar, (item) => {
            if (!(typeof item.value === 'boolean')) {
                mainStore.addErrorToasted({
                    // @ts-ignore
                    toasted: this.$toasted,
                    errors: [item.message]
                });
                valid = false;
            }
        });
        return valid;
    }
    async validateAutocomplete(item, message) {
        const valid = item ? Object.keys(item).length > 0 : false;
        if (!valid) {
            mainStore.addErrorToasted({
                // @ts-ignore
                toasted: this.$toasted,
                errors: [message]
            });
        }
        return valid;
    }
    async submit() {
        this.rodouSubmit = true;
        let isValidCupom = true;
        if (!this.prospexSemCupom) {
            isValidCupom = await this.$refs.searchCupom.validateField();
            if (!isValidCupom) {
                this.prospexSemCupomErrors = ['.'];
            }
            else {
                this.prospexSemCupomErrors = [];
                this.$refs.searchCupom.clearErrors();
            }
        }
        else {
            this.prospexSemCupomErrors = [];
            this.$refs.searchCupom.clearErrors();
        }
        const isValid = await this.$refs.observer.validate();
        this.isValidCursou = await this.verificaCursou();
        let isValidInteresses = true;
        if (!await this.validateAutocomplete(this.entityObject.curso_interesse, 'Curso de interesse deve ser preenchido')) {
            isValidInteresses = false;
            this.curso_interesse_errors = ['Curso de interesse deve ser preenchido'];
        }
        else {
            this.curso_interesse_errors = [];
        }
        if (this.id && !await this.validateAutocomplete(this.entityObject.dias_preferencia, 'Dias de preferência deve ser preenchido')) {
            isValidInteresses = false;
            this.dias_preferencia_errors = ['Dias de preferência deve ser preenchido'];
        }
        else {
            this.dias_preferencia_errors = [];
        }
        if (this.id && !await this.validateAutocomplete(this.entityObject.periodo_preferencia, 'Periodo de preferência deve ser preenchido')) {
            isValidInteresses = false;
            this.periodo_preferencia_errors = ['Periodo de preferência deve ser preenchido'];
        }
        else {
            this.periodo_preferencia_errors = [];
        }
        if (!isValid || !isValidCupom || !this.isValidCursou || !isValidInteresses) {
            // @ts-ignore
            this.$refs.saveButton.changeLoadingStatus(false);
            return;
        }
        if (this.selectedCupom) {
            this.entityObject.parceria_cupom_id = this.selectedCupom.id;
        }
        if (this.atendenteSelected) {
            this.entityObject.atendente_id = this.atendenteSelected.id;
        }
        if (this.alunoIndicouSelected) {
            this.entityObject.indicou_aluno_id = this.alunoIndicouSelected.id;
            this.entityObject.indicou_aluno_nome = this.alunoIndicouSelected.nome;
        }
        if (this.created_at) {
            const data = new Date(this.created_at);
            data.setDate(data.getDate() + 1);
            if (this.created_hour) {
                const horario = this.created_hour.split(':');
                data.setHours(horario[0]);
                data.setMinutes(horario[1]);
            }
            this.entityObject.created_at = data.toISOString();
        }
        this.entityObject.codigo_municipio_completo =
            this.cidadeSelected.codigo_municipio_completo;
        if (this.entityObject.fone_fixo) {
            this.entityObject.fone_fixo = this.entityObject.fone_fixo
                ? parseInt(this.entityObject.fone_fixo.replace(/[^0-9]/g, ''), 0)
                : null;
        }
        if (this.entityObject.celular) {
            this.entityObject.celular = this.entityObject.celular
                ? parseInt(this.entityObject.celular.replace(/[^0-9]/g, ''), 0)
                : null;
        }
        let resultado = null;
        if (this.id) {
            const payload = _.cloneDeep(this.entityObject);
            delete payload.motivo_perda_id;
            delete payload.motivo_perda_descricao;
            delete payload.data_perda;
            resultado = await prospexStore.updateProspex(payload);
        }
        else {
            resultado = await prospexStore.createProspex(this.entityObject);
            if (this.selectedCupom) {
                parceriaCupomHistoricoStore.createParceriaCupomHistorico({
                    parceria_cupom_id: this.selectedCupom.id,
                    status_id: 12,
                    agendamento: null,
                    observacao: '',
                });
            }
            this.id = resultado;
        }
        // @ts-ignore
        this.$refs.saveButton.changeLoadingStatus(false);
        if (resultado) {
            await this.$router.push({ name: 'main-prospexs' });
        }
    }
    async deleteProspex() {
        this.$swal({
            title: 'Deseja realmente deletar o prospex?',
            text: 'Ao deletar um prospex o mesmo não poderá ser restaurado!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (await prospexStore.deleteProspex(this.id)) {
                    this.$router.push({ name: 'main-prospexs' });
                }
            }
        });
    }
    async updateCity(codigo) {
        this.cidadeSelected = await cityStore.getCityByCodigoMunicipio(codigo);
        // @ts-ignore
        this.cidadeSelected.name =
            // @ts-ignore
            this.cidadeSelected.nome_municipio +
                ' - ' +
                // @ts-ignore
                this.cidadeSelected.nome_uf;
        // @ts-ignore
        this.cidadeSelected = {
            // @ts-ignore
            nome_municipio: this.cidadeSelected.nome_municipio,
            // @ts-ignore
            name: this.cidadeSelected.name,
            // @ts-ignore
            nome_uf: this.cidadeSelected.nome_uf,
            codigo_municipio_completo: this.cidadeSelected.codigo_municipio_completo,
        };
    }
    routeEdit() {
        this.$router.push({
            name: 'main-alunos-matricula',
            params: { prospex_id: this.id },
        });
    }
    get userActionsAcessoAOLogDoSistema() {
        return userActionStore.acessoAOLogDoSistema;
    }
    async mounted() {
        await companyConfiguracaoCursoDescontoStore.getCompanyConfiguracaoCursoDescontos();
        if (this.$router.currentRoute.params.id) {
            this.loadingEdit = true;
            this.id = parseInt(this.$router.currentRoute.params.id, 0);
            const responseObject = await prospexStore.getProspex(parseInt(this.$router.currentRoute.params.id, 0));
            if (responseObject.data_atendimento) {
                responseObject.data_atendimento =
                    responseObject.data_atendimento.split('T')[0];
            }
            this.entityObject = { ...responseObject };
            if (responseObject.indicou_aluno_id && responseObject.indicou_aluno_nome) {
                this.alunoIndicouSelected = {
                    tipo: 'Aluno',
                    id: responseObject.indicou_aluno_id,
                    nome: responseObject.indicou_aluno_nome,
                };
            }
            if (responseObject.atendente) {
                this.atendenteSelected = {
                    tipo: 'Usuário',
                    id: responseObject.atendente.id,
                    nome: responseObject.atendente.full_name,
                };
            }
            // @ts-ignore
            if (responseObject.parceria_cupom_id) {
                this.selectedCupom = {
                    tipo: 'Cupom',
                    // @ts-ignore
                    id: responseObject.parceria_cupom_id,
                    nome: responseObject.parceria_cupom.nome,
                };
                this.prospexSemCupom = false;
            }
            else {
                this.prospexSemCupom = true;
            }
            // @ts-ignore
            if (responseObject.created_at) {
                // @ts-ignore
                const dataDate = new Date(responseObject.created_at);
                dataDate.setHours(dataDate.getHours() - (new Date().getTimezoneOffset() / 60) * 2);
                const unformatted = dataDate.toISOString().split('T');
                this.created_at = unformatted[0];
                this.created_hour = unformatted[1].split('.')[0];
            }
            setTimeout(() => {
                this.updateCity(this.entityObject.codigo_municipio_completo);
            }, 100);
            this.needVerify = false;
            this.$nextTick(() => {
                this.loadingEdit = false;
            });
        }
        else {
            this.entityObject = newProspex();
            if (this.$router.currentRoute.params.parceria_cupom_id) {
                const cupom = await parceriaCupomStore.getParceriaCupom(parseInt(this.$router.currentRoute.params.parceria_cupom_id, 0));
                this.selectedCupom = {
                    tipo: 'Cupom',
                    id: cupom.id,
                    nome: cupom.nome,
                    idade: cupom.idade,
                    // @ts-ignore
                    parceria_nome: cupom.parceria.nome,
                };
                // @ts-ignore
                this.$refs.searchCupom.setValue(this.selectedCupom);
            }
            this.cidadeSelected = { codigo_municipio_completo: null };
            this.updateCity(mainStore.userProfile.company.city_codigo_municipio_completo);
            const dataDate = new Date();
            dataDate.setHours(dataDate.getHours() - new Date().getTimezoneOffset() / 60);
            const unformatted = dataDate.toISOString().split('T');
            this.created_at = unformatted[0];
            this.created_hour = unformatted[1].split('.')[0];
            this.needVerify = true;
        }
    }
};
__decorate([
    Watch('entityObject.idade')
], Create.prototype, "watchIdade", null);
__decorate([
    Watch('entityObject.nome')
], Create.prototype, "watchName", null);
__decorate([
    Watch('prospexSemCupom')
], Create.prototype, "onChangeProspexSemCupom", null);
__decorate([
    Watch('selectedCupom')
], Create.prototype, "onChangeCupom", null);
Create = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider,
            SearchCityComponent,
            SearchEntidadeComponent,
            OcorrenciaComponent,
            SaveButton,
            LogShowComponent,
            DateFieldComponent,
            PerdaComercialComponent,
            SearchEntidadeListComponent
        },
    })
], Create);
export default Create;
